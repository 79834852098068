import { CategoryEnum, ActionsEnum } from '@academica-box/analytics/events/index'
import type {
  AcademicaLogin,
  AcademicaShowBanner,
  AcademicaClickBanner,
  AcademicaShowMenu,
  AcademicaClickMenu,
  AcademicaShowMenuBanner,
  AcademicaClickMenuBanner,
  AcademicaClickSearch,
  AcademicaEnteringTextIntoSearch,
  AcademicaViewSearchResult,
  AcademicaShowBurgerMenu,
  AcademicaClickBurgerMenu,
  AcademicaShowContactBlock,
  AcademicaClickContactBlock,
  AcademicaClickSocialVk,
  AcademicaClickSocialTg,
} from '@academica-box/analytics/events/academica'

export const sendAcademicaLogin = (context: AcademicaLogin['context']) => {
  const { $analytics } = useNuxtApp()

  $analytics?.push({
    category: CategoryEnum.Academica,
    action: ActionsEnum.login,
    context,
  })
}

export const sendAcademicaShowBanner = (context: AcademicaShowBanner['context']) => {
  const { $analytics } = useNuxtApp()

  $analytics?.push({
    category: CategoryEnum.Academica,
    action: ActionsEnum.show_banner,
    label: 'header',
    context,
  })
}

export const sendAcademicaClickBanner = (context: AcademicaClickBanner['context']) => {
  const { $analytics } = useNuxtApp()

  $analytics?.push({
    category: CategoryEnum.Academica,
    action: ActionsEnum.click_banner,
    label: 'header',
    context,
  })
}

export const sendAcademicaShowMenu = (label: AcademicaShowMenu['label'], context: AcademicaShowMenu['context']) => {
  const { $analytics } = useNuxtApp()

  $analytics?.push({
    category: CategoryEnum.Academica,
    action: ActionsEnum.show_menu,
    label,
    context,
  })
}

export const sendAcademicaClickMenu = (label: AcademicaClickMenu['label'], context: AcademicaClickMenu['context']) => {
  const { $analytics } = useNuxtApp()

  $analytics?.push({
    category: CategoryEnum.Academica,
    action: ActionsEnum.click_menu,
    label,
    context,
  })
}

export const sendAcademicaShowMenuBanner = (context: AcademicaShowMenuBanner['context']) => {
  const { $analytics } = useNuxtApp()

  $analytics?.push({
    category: CategoryEnum.Academica,
    action: ActionsEnum.show_menu_banner,
    label: 'header',
    context,
  })
}

export const sendAcademicaClickMenuBanner = (context: AcademicaClickMenuBanner['context']) => {
  const { $analytics } = useNuxtApp()

  $analytics?.push({
    category: CategoryEnum.Academica,
    action: ActionsEnum.click_menu_banner,
    label: 'header',
    context,
  })
}

export const sendAcademicaClickSearch = (context: AcademicaClickSearch['context']) => {
  const { $analytics } = useNuxtApp()

  $analytics?.push({
    category: CategoryEnum.Academica,
    action: ActionsEnum.click_search,
    context,
  })
}

export const sendAcademicaEnteringTextIntoSearch = (context: AcademicaEnteringTextIntoSearch['context']) => {
  const { $analytics } = useNuxtApp()

  $analytics?.push({
    category: CategoryEnum.Academica,
    action: ActionsEnum.entering_text_into_search,
    context,
  })
}

export const sendAcademicaViewSearchResult = (context: AcademicaViewSearchResult['context']) => {
  const { $analytics } = useNuxtApp()

  $analytics?.push({
    category: CategoryEnum.Academica,
    action: ActionsEnum.view_search_result,
    context,
  })
}

export const sendAcademicaShowBurgerMenu = (context: AcademicaShowBurgerMenu['context']) => {
  const { $analytics } = useNuxtApp()

  $analytics?.push({
    category: CategoryEnum.Academica,
    action: ActionsEnum.show_burger_menu,
    context,
  })
}

export const sendAcademicaClickBurgerMenu = (context: AcademicaClickBurgerMenu['context']) => {
  const { $analytics } = useNuxtApp()

  $analytics?.push({
    category: CategoryEnum.Academica,
    action: ActionsEnum.click_burger_menu,
    context,
  })
}

export const sendAcademicaShowContactBlock = (context: AcademicaShowContactBlock['context']) => {
  const { $analytics } = useNuxtApp()

  $analytics?.push({
    category: CategoryEnum.Academica,
    action: ActionsEnum.show_contact_block,
    label: 'footer',
    context,
  })
}

export const sendAcademicaClickContactBlock = (context: AcademicaClickContactBlock['context']) => {
  const { $analytics } = useNuxtApp()

  $analytics?.push({
    category: CategoryEnum.Academica,
    action: ActionsEnum.click_contact_block,
    label: 'footer',
    context,
  })
}

export const sendAcademicaClickSocialVk = (context: AcademicaClickSocialVk['context']) => {
  const { $analytics } = useNuxtApp()

  $analytics?.push({
    category: CategoryEnum.Academica,
    action: ActionsEnum.click_social_vk,
    label: 'footer',
    context,
  })
}

export const sendAcademicaClickSocialTg = (context: AcademicaClickSocialTg['context']) => {
  const { $analytics } = useNuxtApp()

  $analytics?.push({
    category: CategoryEnum.Academica,
    action: ActionsEnum.click_social_tg,
    label: 'footer',
    context,
  })
}
